import "../App.css"
import LogoN54Primary from "../Img/logo-n54-primary.svg"
import IconLocation from "../Img/Icon/location.svg"
import IconTime from "../Img/Icon/time.svg"
import IconPhone from "../Img/Icon/phone.svg"
import IconEmail from "../Img/Icon/email.svg"

function Contact() {
    return (
        <>
            <section className="py-6 bg-contact">
                <div className="container text-center">
                    <h1 className=" fw-bold text-white">Contacto</h1>
                    <h5 className="text-white fw-normal">Nuestro equipo de expertos está listo para escucharlo.</h5>
                </div>
            </section>

            <section className="bg-F7F7F7 py-5">
                <div className="container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-4">
                    <div className="border-top-D2AC68">
                        <div class="row row-cols-1 row-cols-md-1 row-cols-lg-4 gx-0 gy-0 border text-center">

                            <div class="col">
                                <div class="card h-100 border-0 rounded-0 bg-white">
                                    <div class="card-body py-6">
                                        <img src={IconLocation} width={45} className="mx-auto mb-3" alt="Icono" />
                                        <h5 class="card-title text-center fw-semibold">Dirección</h5>
                                        <p class="card-text mt-3 h6 lh-base opacity-75">
                                            Blvd a 2766 - 1, 36670 Villas de Irapuato, Gto.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card h-100 border-0 rounded-0 bg-white">
                                    <div class="card-body py-6">
                                        <img src={IconTime} width={45} className="mx-auto mb-3" alt="Icono" />
                                        <h5 class="card-title text-center fw-semibold">Horarios de Atención</h5>
                                        <p class="card-text mt-4 h6 lh-base opacity-75">
                                            Lunes a Viernes de: <br />
                                            9:00 am a 5:00 pm
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card h-100 border-0 rounded-0 bg-white">
                                    <div class="card-body py-6">
                                        <img src={IconPhone} width={40} className="mx-auto mb-3" alt="Icono" />
                                        <h5 class="card-title text-center fw-semibold">Teléfonos</h5>
                                        <p class="card-text mt-4 h6 lh-base opacity-75">
                                            <span className="bi bi-telephone me-2"></span> 46 2623 0228
                                            <br />
                                            <span className="bi bi-whatsapp me-2"></span> (+52) 46 1228 4007
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card h-100 border-0 rounded-0 bg-white">
                                    <div class="card-body py-6">
                                        <img src={IconEmail} width={45} className="mx-auto mb-3" alt="Icono" />
                                        <h5 class="card-title text-center fw-semibold">Email</h5>
                                        <p class="card-text mt-4 h6 lh-base opacity-75">
                                            contacto@notaria54irapuato.com
                                        </p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </section>


        </>

    )
}

export default Contact;