import LogoN54Primary from "../Img/logo-n54-primary.svg"


function Services() {
    return (
        <>
        
        <section className="py-6 bg-servicios">
                <div className="container text-center">
                    <h1 className=" fw-bold text-white">Servicios</h1>
                    <h5 className="text-white fw-normal">Certeza y seguridad jurídica en sus tramites</h5>
                </div>
            </section>

            <section className="py-5">

                <div className="container">

                    <div class="row row-cols-1 row-cols-md-2 g-4">

                        <div class="col">
                            <div class="card h-100 border">
                                <div>
                                    <div class="card-body">
                                        <h5 class="card-title fw-semibold text-center">Inmobiliarios</h5>
                                        <ul className="mt-3">
                                            <li className="h6">Contratos en sus diversos tipos</li>
                                            <li className="h6">Constitución de usufructo</li>
                                            <li className="h6">Constitución de copropiedad</li>
                                            <li className="h6">Constitución de servidumbre</li>                                                                                        
                                            <li className="h6">Fusión de lotes de terreno</li>
                                            <li className="h6">Constitución de fideicomisos</li>
                                            <li className="h6">Constitución de régimen de propiedad en condominio</li>
                                            <li className="h6">Subdivisión de lotes de terreno</li>
                                            <li className="h6">Contrato de compraventa de bienes inmobiliarios</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card h-100 border">
                                <div>
                                    <div class="card-body">
                                        <h5 class="card-title fw-semibold text-center">Sociedades</h5>
                                        <ul className="mt-3">
                                            <li className="h6">Constitución de sociedades mercantiles y civiles</li>
                                            <li className="h6">Elaboración de actas de asamblea</li>
                                            <li className="h6">Protocolización de actas de asamblea</li>
                                            <li className="h6">Fusión de sociedades</li>                                            
                                            <li className="h6">Liquidación de sociedades</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card h-100 border">
                                <div>
                                    <div class="card-body">
                                        <h5 class="card-title fw-semibold text-center">Familia</h5>
                                        <ul className="mt-3">                                            
                                            <li className="h6">Testamentos</li>
                                            <li className="h6">Sucesiones</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card h-100 border">
                                <div>
                                    <div class="card-body">
                                        <h5 class="card-title fw-semibold text-center">Otros servicios</h5>
                                        <ul className="mt-3">
                                            <li className="h6">Certificación de documentos</li>
                                            <li className="h6">Ratificación de firmas</li>
                                            <li className="h6">Elaboración de contratos, poderes</li>                                            
                                            <li className="h6">Smart Contracts con Blockchain</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>

            </section>


        </>
    )
}

export default Services;

