import "../App.css"
import IconRespeto from "../Img/Icon/respeto.svg"
import IconLegalidad from "../Img/Icon/legalidad.svg"
import IconHonestidad from "../Img/Icon/honestidad.svg"
import IconTimeRed from "../Img/Icon/time-red.svg"
import IconTransparencia from "../Img/Icon/transparencia.svg"


function ValuesSection() {
    return (
        <>

            <section className="py-5">
                <div className="container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-3">

                    <h3 className="text-center mb-5 fw-bold">
                        Nuestros valores
                    </h3>

                    <div class="row row-cols-1 row-cols-md-3 row-cols-lg-5 gy-3 text-center justify-content-evenly">

                        <div class="col">
                            <div class="card border-0">
                                <div class="card-body">
                                    <img src={IconRespeto} width={60} className="pb-4" alt="Icon" />
                                    <h5 class="card-title text-center fw-medium">Respeto y calidez en el trato</h5>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card border-0">
                                <div class="card-body">
                                    <img src={IconLegalidad} width={55} className="pb-4" alt="Icon" />
                                    <h5 class="card-title text-center fw-medium">Legalidad</h5>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card border-0">
                                <div class="card-body">
                                    <img src={IconHonestidad} width={45} className="pb-4" alt="Icon" />
                                    <h5 class="card-title text-center fw-medium">Honestidad</h5>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card border-0">
                                <div class="card-body">
                                    <img src={IconTimeRed} width={55} className="pb-4" alt="Icon" />
                                    <h5 class="card-title text-center fw-medium">Prontitud</h5>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card border-0">
                                <div class="card-body">
                                    <img src={IconTransparencia} width={50} className="pb-4" alt="Icon" />
                                    <h5 class="card-title text-center fw-medium">Transparencia</h5>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

        </>
    )
}

export default ValuesSection;



