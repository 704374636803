import "../App.css"

function Footer() {
    return (
        <>
            <div class="container">
                <footer class="pt-5">
                    <div class="row">
                        <div class="col-12 col-md-3 mb-3">                            
                            <h5 className="fw-semibold h6">Mapa del sitio</h5>
                            <ul class="nav flex-column">
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Inicio</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Nosotros</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Servicios</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Contacto</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Barra de Abogados</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Aviso de Privacidad</a></li>
                            </ul>                            
                        </div>

                        <div class="col-12 col-md-3 mb-3">
                            <h5 className="fw-semibold h6">Contáctanos</h5>
                            <ul class="nav flex-column">
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary"><span className="bi bi-telephone me-2"></span>46 2623 0228</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary"><span className="bi bi-whatsapp me-2"></span>(+52) 46 1228 4007</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary"><span className="bi bi-envelope me-2"></span>contacto@notaria54irapuato.com</a></li>
                            </ul>
                        </div>

                        <div class="col-md-5 offset-md-1 mb-3">
                        <h5 className="fw-semibold h6">Ubicación</h5>
                            <section>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.623864890658!2d-101.39287342403647!3d20.68487619949951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842c7fc774deddeb%3A0xdd3db6e190763e01!2sNotar%C3%ADa%20Publica%2054!5e0!3m2!1ses-419!2smx!4v1720830036453!5m2!1ses-419!2smx" width="100%"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </section>
                        </div>
                    </div>

                    <div class="d-flex flex-column flex-sm-row justify-content-between mt-2 mb-2 border-top">
                        <p className="small mt-4">&copy; 2024 Notaria Pública 54, Todos los derechos reservados.</p>
                        <ul class="list-unstyled d-flex">
                            <li class="ms-3"><a class="link-body-emphasis" href="#"><svg class="bi" width="24" height="24"></svg></a></li>
                            <li class="ms-3"><a class="link-body-emphasis" href="#"><svg class="bi" width="24" height="24"></svg></a></li>
                            <li class="ms-3"><a class="link-body-emphasis" href="#"><svg class="bi" width="24" height="24"></svg></a></li>
                        </ul>
                    </div>
                </footer>
            </div>
        </>

    )
}

export default Footer;