import "../App.css"
import ValuesSection from "../Components/ValuesSection";
import LogoN54Primary from "../Img/logo-n54-primary.svg"

function AboutUs() {
    return (
        <>


            <section className="py-6 bg-aboutUs">
                <div className="container text-center">
                    <h1 className=" fw-bold text-white">Nosotros</h1>
                    <h5 className="text-white fw-normal">Desafiarnos a nosotros mismos para atraer formas de pensar nuevas y originales.</h5>
                </div>
            </section>

            <section className="bg-F7F7F7 py-5">
                <div className="container col-12">
                    <div className="card border bg-white rounded-0">
                        <div className="card-body border-top-8D0E3A">
                            <div class="row align-items-center gy-5 gx-5">
                                <div class="col-12 col-md-4 text-center">
                                    <a class="navbar-brand" href="/"><img className="img-fluid" src={LogoN54Primary} alt="Logotipo" /></a>
                                </div>
                                <div class="col-12 col-md-8">
                                    <h5 className="fw-semibold">La Notaría Pública 54 de la Ciudad de Irapuato</h5>
                                    <p className="mt-3 opacity-75 lh-base h6 txt-justify">
                                        Es un grupo de profesionales en el Derecho, que cuenta con personal altamente capacitado y tecnología de vanguardia, lo que garantiza que nuestras operaciones sean más ágiles y puntuales, siempre con apego a la legislación vigente y bajo un estricto código de ética, para lograr certeza jurídica en cada uno de los servicios que brindamos a nuestros clientes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row row-cols-1 row-cols-md-2 g-4 mt-1 text-center">
                        <div class="col">
                            <div class="card h-100 border bg-white">
                                <div class="card-body py-5">
                                    <h5 class="card-title fw-semibold">Misión</h5>
                                    <p class="card-text mt-3 h6 lh-base opacity-75">
                                        Brindar servicios notariales de excelencia y calidad, que nos permita construir un laso de confianza con nuestros clientes y a su vez confieran seguridad jurídica en su persona y bienes.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100 border">
                                <div class="card-body bg-white py-5">
                                    <h5 class="card-title fw-semibold">Visión</h5>
                                    <p class="card-text mt-3 h6 lh-base opacity-75">
                                        Ser la Notaría líder de la ciudad de Irapuato, respaldado por un equipo de trabajo profesional y experimentado, que se distinga por ofrecer servicios notariales de excelencia.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>

            <ValuesSection/>


            <section className="py-5 bg-F7F7F7">
                <div className="container col-sm-12 col-md-12 col-12 col-lg-10 col-xl-6">

                    <div class="card h-100 border bg-white">
                        <div class="card-body rounded-0 border-top-D2AC68">

                            <div className="text-center">
                                <h4 className=" fw-semibold mt-3">¿Qué hacemos?</h4>
                                <p className="h6 py-4 lh-base opacity-75">En la notaria 54 de Irapuato tenemos la tarea de recibir, interpretar, redactar y dar forma legal a la voluntad de las personas, así como dar certeza jurídica a los actos de nuestros clientes, sean personas fí­sicas o morales, y a los hechos que por solicitud de estos consten ante fe de notario. Ponemos a su alcance los siguientes servicios notariales:</p>
                            </div>
                            <div className="">
                                <ul>
                                    <li className="h6 opacity-75 lh-base">Nos encargamos de la elaboración y trámite de escrituras públicas para los hechos y actos que la ley disponga o cuando sea voluntad de las partes hacerlo.</li>
                                    <li className="h6 opacity-75 lh-base">Elaboración de escrituras públicas para la cancelación de hipotecas.</li>
                                    <li className="h6 opacity-75 lh-base">Guarda, apertura y publicación de testamentos.</li>
                                    <li className="h6 opacity-75 lh-base">Expedición de copias de las escrituras públicas.</li>
                                    <li className="h6 opacity-75 lh-base">Reconocimiento de documentos privados, dejando constancia de la manifestación del interesado y el contenido del documento.</li>
                                    <li className="h6 opacity-75 lh-base">Autenticaciones, haciendo precisión del documento que tuvo a la vista para realizarla. Puede autenticar copias o firmas.</li>
                                    <li className="h6 opacity-75 lh-base">Fe de vida, testimonio escrito de la supervivencia de una persona.</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                </div>
            </section>

           
        </>

    )
}

export default AboutUs;