import "../App.css"
import InmueblePng from "../Img/Inicio/inmueble.png"
import SociedadPng from "../Img/Inicio/sociedad.png"
import OtrosServiciosPng from "../Img/Inicio/otrosServicios.png"
import FamiliaPNG from "../Img/Inicio/familia.png"
import ImgCarrousel2 from "../Img/Inicio/carrousel-2.jpg"
import ImgCarrousel1 from "../Img/Inicio/carrousel-1.jpg"
import ValuesSection from "../Components/ValuesSection"

function Home() {
  return (
    <>

      <div className="">
        <div id="carouselExampleIndicators" class="carousel slide">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src={ImgCarrousel1} class="img-fluid w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src={ImgCarrousel2} class="d-block w-100" alt="..." />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <section className="bg-F7F7F7 py-5">
        <div className="container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-3">
          <div class="row row-cols-1 row-cols-md-1 row-cols-lg-3 gx-0 gy-0 border-top-D2AC68">

            <div class="col">
              <div class="card h-100 border rounded-0 bg-white">
                <div class="card-body">
                  <h5 class="card-title text-center fw-semibold">¿Quiénes somos?</h5>
                  <p class="card-text mt-4 h6 lh-base txt-justify opacity-75">
                    La Notaría Pública 54 de Irapuato es un grupo de profesionales en el Derecho, que cuenta con personal altamente capacitado y tecnología de vanguardia, lo que garantiza que nuestras operaciones sean más ágiles y puntuales…
                  </p>
                </div>
                <div class="card-footer no-style pb-5">
                  <div class=" text-center">
                    <a class="btn btn-primary btn-lg rounded-1" type="button" href="/nosotros">Nosotros</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card h-100 border rounded-0 bg-white">
                <div class="card-body ">
                  <h5 class="card-title text-center fw-semibold">¿Qué hacemos?</h5>
                  <p class="card-text mt-4 h6 lh-base txt-justify opacity-75">
                    En la Notaría Pública 54 de Irapuato tenemos la tarea de recibir, interpretar, redactar y dar forma legal a la voluntad de las personas, así como dar certeza jurídica a los actos de nuestros clientes, sean personas fí­sicas o morales…
                  </p>
                </div>
                <div class="card-footer no-style pb-5">
                  <div class="text-center">
                    <a class="btn btn-primary btn-lg rounded-1" type="button" href="/servicios">Lo que hacemos</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card h-100 border rounded-0 bg-white">
                <div class="card-body">
                  <h5 class="card-title text-center fw-semibold">Nuestros servicios
                  </h5>
                  <p class="card-text mt-4 h6 lh-base txt-justify opacity-75">
                    Certeza y seguridad jurídica en sus tramites, con un excelente trato de calidez y calidad.
                  </p>
                </div>
                <div class="card-footer no-style pb-5">
                  <div class=" text-center">
                    <a class="btn btn-primary btn-lg rounded-1" type="button" href="/servicios">Servicios</a>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>

      <ValuesSection />

      <section className="bg-8D0E3A">
        <div className="container col-12 col-md-4 py-4 text-center">
          <div className="card bg-8D0E3A border-0">
            <div className="card-body">
              <h3 className=" fw-bold text-white">
                Horarios y días de atención
              </h3>
              <h5 className="mt-3 mb-4 text-white">
                Lunes a Viernes de 9:00 am a 5:00 pm
              </h5>
              <a type="button" href="https://wa.me/524612284007" class="btn btn-lg btn-secondary" target="_blank"><span class="">Contáctanos</span></a>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 text-center bg-F7F7F7">
        <div className="container">

          <h3 className="text-center mb-5 fw-bold">
            Nuestros Servicios
          </h3>


          <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4 row-cols-md-2 gy-3 gx-3">
            <div class="col">
              <div class="card h-100 bg-white border rounded-0">
                <img src={InmueblePng} class="mx-auto pt-5" alt="..." width={125} />
                <div class="card-body border-bottom-8D0E3A">
                  <h5 class="card-title fw-semibold text-center">Inmobiliarios</h5>
                  <p class="card-text mt-4 text-center h6 lh-base opacity-75">
                    Una de las principales funciones del notario es brindar seguridad jurídica al patrimonio de las familias a través de la formalización de la compraventa de inmuebles en escritura pública.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100 bg-white border rounded-0">
                <img src={FamiliaPNG} class="mx-auto pt-5" alt="..." width={125} />
                <div class="card-body border-bottom-D2AC68">
                  <h5 class="card-title fw-semibold text-center">Familia</h5>
                  <p class="card-text mt-4 text-center h6 lh-base opacity-75">
                    Notario público hace constar de manera legal la última voluntad de una persona, respecto al reparto de bienes una vez fallecida.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100 bg-white border rounded-0">
                <img src={SociedadPng} class="mx-auto pt-5" alt="..." width={125} />
                <div class="card-body border-bottom-8D0E3A">
                  <h5 class="card-title fw-semibold text-center">Sociedades</h5>
                  <p class="card-text mt-4 text-center h6 lh-base opacity-75">
                    El notario es un aliado importante del empresario, ya que da fe pública a la constitución de las sociedades, las cuales comprenden los estatutos sociales, en los que se definen el tipo de sociedad que se formará, así como los derechos y obligaciones que los socios adquieren.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100 bg-white border rounded-0">
                <img src={OtrosServiciosPng} class="mx-auto pt-5" alt="..." width={125} />
                <div class="card-body border-bottom-D2AC68">
                  <h5 class="card-title fw-semibold text-center">Otros servicios</h5>
                  <p class="card-text mt-4 text-center h6 lh-base opacity-75">
                    Notario público es un profesional del derecho cuya finalidad es dar validez, legalidad y autenticidad a documentos como los contratos.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <a class="btn btn-primary btn-lg rounded-1 mt-5 mx-auto" type="button" href="/servicios">Más detalles</a>

        </div>
      </section>

    </>
  );
}

export default Home;
