import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Home from "./Pages/Home"
import AboutUs from './Pages/AboutUs';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import BarraDeAbogados from './Pages/BarraDeAbogados';

function App() {
  return (
    <Router>
      <Navbar/>
      <Routes>        
        <Route path="/" element={<Home />} />        
        <Route path="/nosotros" element={<AboutUs />} />
        <Route path="/servicios" element={<Services />} />
        <Route path="/contacto" element={<Contact />} />
        <Route path="/barra-de-abogados" element={<BarraDeAbogados />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
