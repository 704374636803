import "../App.css"
import IconEmail from "../Img/Icon/email.svg"
import MujerSilueta from "../Img/mujer-silueta.png"
import HombreSilueta from "../Img/hombre-silueta.png"

function BarraDeAbogados() {
    return (
        <>
            <section className="py-6 bg-aboutUs">
                <div className="container text-center">
                    <h1 className=" fw-bold text-white">Barra de Abogados</h1>
                    <h6 className="text-white fw-normal">Expertos en materia</h6>
                </div>
            </section>

            <section className="bg-F7F7F7">

                <div className="container py-5">

                    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3  text-center align-items-center">

                        <div class="col">
                            <div class="card h-100 border rounded-0 bg-white">
                                <div class="card-body">
                                    <img src={HombreSilueta} class="card-img-top mb-4 rounded-2" alt="..." />
                                    <h6 class="card-title">Lic. Juan Manuel Andrade Vega</h6>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card h-100 border rounded-0 bg-white">
                                <div class="card-body">
                                    <img src={MujerSilueta} class="card-img-top mb-4 rounded-2" alt="..." />
                                    <h6 class="card-title ">Lic. Maria García García</h6>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card h-100 border rounded-0 bg-white">
                                <div class="card-body">
                                    <img src={HombreSilueta} class="card-img-top mb-4 rounded-2" alt="..." />
                                    <h6 class="card-title ">Lic. Geovany Sánchez Nuñez</h6>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card h-100 border rounded-0 bg-white">
                                <div class="card-body">
                                    <img src={MujerSilueta} class="card-img-top mb-4 rounded-2" alt="..." />
                                    <h6 class="card-title ">Lic. Dulce Rosales Zarate</h6>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card h-100 border rounded-0 bg-white">
                                <div class="card-body">
                                    <img src={MujerSilueta} class="card-img-top mb-4 rounded-2" alt="..." />
                                    <h6 class="card-title ">Lic. Carolina Servin Pérez</h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
        </>

    )
}

export default BarraDeAbogados;